<template>
  <b-card>
    <!-- use component -->
    <view-popup-data ref="viewPopupData" />
    <click-manage-credit-modal ref="ClickManageCreditModal" v-if="_isAdmin" />
    <click-edit-profile-modal ref="ClickEditProfileModal" />
    <!-- end use component -->
    <b-row>
      <!-- User Info: Left col -->
      <b-col
        cols="21"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            src="https://ichi.pro/assets/images/max/724/0*Zw1ZfCk0svcXVQXg.jpg"
            text="avatarText(userData.fullName)"
            size="104px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1" v-if="_isAdmin">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="flat-primary"
                @click="$refs.ClickManageCreditModal.show(_creditDataAmount)"
              >
                Click Manage Credit
              </b-button>
              <!-- <h4 class="mb-0">userData.fullName</h4> -->
              <!-- <span class="card-text"> userData.email </span> -->
            </div>
            <div class="mb-1">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="flat-primary"
                @click="$refs.ClickEditProfileModal.show(cp(userData))"
              >
                Click Edit Profile Data
              </b-button>
              <!-- <h4 class="mb-0">userData.fullName</h4> -->
              <!-- <span class="card-text"> userData.email </span> -->
            </div>
            <div class="d-flex flex-wrap">
              <!-- <b-button variant="primary"> Add Credit </b-button> -->
            </div>
          </div>
        </div>

        <!-- User Stats -->
        <div class="d-flex align-items-center mt-2">
          <div class="d-flex align-items-center mr-2">
            <b-avatar variant="light-primary" rounded>
              <feather-icon icon="DollarSignIcon" size="18" />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{ withCommas(_sumBuyPackage) }}
              </h5>
              <small> Purchase Amount </small>
            </div>
          </div>

          <div class="d-flex align-items-center">
            <b-avatar variant="light-success" rounded>
              <feather-icon icon="TrendingUpIcon" size="18" />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{ _creditDataAmount }}
              </h5>
              <small>Credit Balance</small>
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col cols="12" xl="6">
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon icon="UserIcon" class="mr-75" />
              <span class="font-weight-bold">Username</span>
            </th>
            <td class="pb-50">{{ userData.username }}</td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="CheckIcon" class="mr-75" />
              <span class="font-weight-bold">Email</span>
            </th>
            <td class="pb-50 text-capitalize">{{ userData.email }}</td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="StarIcon" class="mr-75" />
              <span class="font-weight-bold">Role</span>
            </th>
            <td class="pb-50 text-capitalize">-</td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="FlagIcon" class="mr-75" />
              <span class="font-weight-bold">Country</span>
            </th>
            <td class="pb-50">{{ _getCountryForPhoneData }}</td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="FlagIcon" class="mr-75" />
              <span class="font-weight-bold">Access Token</span>
            </th>
            <td class="pb-50">
              <b-button
                style="padding: 0 0"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="flat-primary"
                @click="clickShow('Access Token', _userDataAccessToken)"
              >
                Click Show
              </b-button>
            </td>
          </tr>

          <tr>
            <th class="pb-50">
              <feather-icon icon="FlagIcon" class="mr-75" />
              <span class="font-weight-bold">Url Repository</span>
            </th>
            <td class="pb-50">
              <b-button
                style="padding: 0 0"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="flat-primary"
                @click="clickShow('Url Repository', _userDataURLRepository)"
              >
                Click Show
              </b-button>
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import Ripple from "vue-ripple-directive"
import { mapState } from "vuex"
export default {
  directives: {
    Ripple,
  },
  watch: {
    "reload.creditData"() {
      this.fetchUserData()
    },
  },

  data() {
    return {
      userData: "",
    }
  },
  components: {
    ViewPopupData: () => import("./ViewPopupData.vue"),
    ClickManageCreditModal: () => import("./ClickManageCreditModal.vue"),
    ClickEditProfileModal: () => import("./ClickEditProfileModal.vue"),
  },

  computed: {
    ...mapState("UserViews", ["reload"]),
    _isAdmin() {
      return this.$route.meta.action !== "client"
    },
    _creditDataAmount() {
      try {
        return this.userData.credit_data[0].amount
      } catch (error) {
        return ""
      }
    },
    _sumBuyPackage() {
      try {
        return this.userData.buy_package_data.reduce((pre, current) => {
          return (pre += current.amount * 1)
        }, 0)
      } catch (error) {
        return 0
      }
    },
    _getCountryForPhoneData() {
      try {
        return this.userData.phone_datas[0].phone_country_code_data.country
      } catch (error) {
        return "-"
      }
    },
    _userDataAccessToken() {
      try {
        return this.userData.access_token
      } catch (error) {
        return ""
      }
    },
    _userDataURLRepository() {
      try {
        return this.userData.url_repository
      } catch (error) {
        return ""
      }
    },
  },
  methods: {
    clickShow(text, value) {
      this.$refs.viewPopupData.show({ text, value })
    },
    async fetchUserData() {
      try {
        const user_id = this.$route.params.user_id
        this.userData = await this.api.get(`/api/userData/${user_id}`)
      } catch (error) {
        console.error(error)
        return ""
      }
    },
  },
  async mounted() {
    this.fetchUserData()
  },
}
</script>
